import { GetServerSideProps } from 'next';
import { Fragment } from 'react';

import { wrapper } from 'src/redux/store/store';
import { getHomepageHandler } from 'lib/page.service';

export const getServerSideProps: GetServerSideProps = wrapper
// @ts-ignore
  .getServerSideProps(getHomepageHandler());

export default Fragment;
